.about-title {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(1,75,88);
    background: linear-gradient(0deg, rgba(1,75,88,1) 0%, rgba(34,40,49,1) 80%);
}
.about-title > img {
    padding-top: 6rem;
    width: 150px;
}
.about-description {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 35px;
    padding: 0 10px;
    background: rgb(1,75,88);
    background: linear-gradient(180deg, rgba(1,75,88,1) 10%, rgba(34,40,49,1) 100%);
}
.about-bio {
    min-width: 280px;
    max-width: 400px;
    min-height: 120px;
    max-height: 300px;
    font-size: 1.6rem;
    text-align: center;
    color: white;
}
.about-skills {
    width: 80vw;
    max-width: 310px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
}
.about-skills > li {
    list-style: none;
}
.about-skills > li > img {
    width: 40px;
}
.download-cv {
    /* width: 200px; */
    height: 40px;
    border-radius: 10px;
    border: none;
    outline: none;
    background: rgb(0,173,181);
    background: linear-gradient(180deg, rgba(0,173,181,1) 0%, rgba(0,124,124,1) 100%);
}
.download-cv > a {
    cursor: none;
    text-decoration: none;
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0 20px;
}
@media(min-width: 1024px) {
    .About {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5vw;
        background: rgb(34,40,49);
        background: linear-gradient(0deg, rgba(34,40,49,1) 0%, rgba(1,75,88,1) 50%, rgba(34,40,49,1) 100%);
    }
    .about-title, .about-description {
        background: transparent;
        width: auto;
    }
    .about-title > img {
        padding-top: 0;
        /* width: 170px; */
    }
    .download-cv > a {
        cursor: pointer;
    }
}