:root {
    /* Colors */
    --soft-dark: #393e46;
    --cyan: #00f8f8;
    --white: white;
    --green-dark: #014b58;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html {
    font-size: 62.5%;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.no-scroll {
    overflow: hidden;
    /* position: fixed; */
}