.Home {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
    background-color: #222831;
    background-image: linear-gradient(0deg, #222831 0%, #393e46 100%);
}
.presentation {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: aquamarine; */
}
.greetings {
    font-size: 8.2rem;
    font-weight: bold;
    color: white;
}
.greetings, .name, .career {
    color: var(--white);
}
.greetings > span {
    color: var(--cyan);
}
.name {
    font-size: 2.4rem;
    font-weight: 500;
}
.career {
    font-size: 2.2rem;
    font-weight: bold;
}

.information {
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.information-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}
.information-list > .Phone ,
.information-list > .Email ,
.information-list > .Address  {
    width: 100%;
    display: flex;
    /* justify-content: left; */
    align-items: center;
}
.information-list > .Phone > img,
.information-list > .Email > img,
.information-list > .Address > img {
    width: 20px;
    margin-right: 10px;
}
.information-list > .Phone > a,
.information-list > .Email > a,
.information-list > .Address > a {
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--white);
}

@media(min-width: 1024px) {
    .greetings {
        font-size: 12rem;
    }
    .name {
        font-size: 3.6rem;
    }
    .career {
        font-size: 3.3rem;
    }
    .information-list > .Phone ,
    .information-list > .Email ,
    .information-list > .Address  {
        justify-content: center;
    }
    .information-list > .Phone > a,
    .information-list > .Email > a,
    .information-list > .Address > a {
        font-size: 2rem;
    }
    .information-list > .Phone > img,
    .information-list > .Address > img {
        width: 25px;
    }
    .information-list > .Email > img{
        width: 30px;
    }
}