.projects-title {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(1,75,88);
    background: linear-gradient(180deg, rgba(1,75,88,1) 0%, rgba(34,40,49,1) 100%);
}
.projects-title > img {
    padding-top: 6rem;
    width: 100px;
}
.projects-carousel {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: linear-gradient(0deg, rgba(0,105,108,1) 0%, rgba(34,40,49,1) 80%);
}
.carousel-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(57,62,70,0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(57,62,70,0.25);
}

@media(min-width: 1024px) {
    .Projects {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(0,124,124);
        background: linear-gradient(0deg, rgba(0,124,124,1) 0%, rgba(34,40,49,1) 50%, rgba(1,75,88,1) 100%); 
    }
    .projects-container {
        width: 100%;
        display: flex; /* Cambiar a Flex */
        justify-content: center;
        align-items: center;
        column-gap: 5vw;
        background: rgba(57,62,70,0.5);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(57,62,70,0.25);
    }
    .projects-title, .projects-carousel {
        background: transparent;
        background-color: transparent;
        background-image: none;
        width: auto;
        height: auto;
    }
    .projects-title > img {
        padding-top: 0;
        /* width: 120px; */
    }
    .carousel-container {
        background: transparent;
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        border: none;
    }
}