.technical_skills-title {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #222831;
    background-image: linear-gradient(180deg, #222831 10%, #393e46 100%);   
}
.technical_skills-title > img {
    padding-top: 6rem;
    width: 150px;
}
.technical_skills-content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #014b58;
    background-image: linear-gradient(180deg, #393e46 10%, #014b58  100%);   
}
/* ======= Skill List ========= */
.technical-skills__list {
    list-style: none;
    width: 95vw;
    max-width: 380px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
}
.technical-skills__list > li {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
}
.technical-skills__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-width: 260px;
    max-width: 400px;
    padding: 0 10px 0;
    justify-content: center;
}
.skill-description {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
}
.skill-name {
    font-size: 2.2rem;
    font-weight: bold;
    color: var(--white);
}
.skill-progress{
    font-size: 2rem;
    margin-top: 5px;
    color: var(--white);
}
.progress-bar {
    width: 100%;
    height: 15px;
    margin-top: 3px;
    border-radius: 15px;
    background-color: var(--soft-dark);
}
.progress-bar > div {
    height: 100%;
    border-radius: 15px;
    background-color: var(--cyan);
}
/* ==== Education ==== */
.education-title {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(1,75,88);
    background: linear-gradient(180deg, rgba(1,75,88,1) 0%, rgba(0,124,124,1) 100%);
}
.education-title > img {
    padding-top: 6rem;
    width: 90px;
}
.education-description {
    list-style: none;
    width: 100vw;
    height: 100vh;
    /* padding: 0px 20px; */
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background: rgb(1,75,88);
    background: linear-gradient(0deg, rgba(1,75,88,1) 0%, rgba(0,124,124,1) 100%);
}
.education-description > li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
}
.card {
    width: 100%;
    height: auto;
    min-width: 260px;
    max-width: 600px;
    width: 100%;
    height: auto;
    padding: 26px 30px;
    border-radius: 20px;
    box-shadow: 0px 4px 28px 5px rgba(0,0,0,0.30);
    background-color: #222831;
    background-image: linear-gradient(1900deg, #222831 0%, #393e46 100%);
}
.card-title {
    font-size: 2.0rem;
    font-weight: 700;
    color: var(--white);
    text-align: left;
}
.card-title > a {
    cursor: none;
    text-decoration: none;
    color: var(--cyan);
}
.card-description{
    display: flex;
    max-width: 600px;
    max-height: 300px; 
    flex-direction: column;
    font-size: 1.6rem;
    font-weight: 400;
    text-align: left;
}
.card-description > p:nth-child(1){
    font-weight: bold;
    color: var(--cyan);
}
.card-description > p:nth-child(2){
    margin-top: 5px;
    color: var(--white);
}
.button-vp {
    width: auto;
    height: 40px;
    padding: 0 15px 0;
    margin-top: 20px;
    border: none;
    border-radius: 10px;
    background: rgb(0,173,181);
    background: linear-gradient(180deg, rgba(0,173,181,1) 0%, rgba(0,124,124,1) 100%);
}
.button-vp > a {
    cursor: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--white);
}
@media(min-width: 1024px) {
    .Skills {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .technical-container {
        width: 100vw;
        height: 100vh;
        display: flex; /* Cambiar a Flex */
        justify-content: center;
        align-items: center;
        column-gap: 5vw;
        background-color: #222831;
        background-image: linear-gradient(180deg, #222831 10%, #393e46 100%);  
    }
    .technical_skills-title, .technical_skills-content {
        background: transparent;
        background-color: transparent;
        background-image: none;
        width: auto;
    }
    .technical_skills-title > img {
        padding-top: 0;
        /* width: 170px; */
    }
    .education-container {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5vw;
        background: rgb(1,75,88);
        background: linear-gradient(0deg, rgba(1,75,88,1) 0%, rgba(1,75,88,1) 13%, rgba(0,124,124,1) 50%, rgba(57,62,70,1) 90%);
    }
    .education-title, .education-description {
        background: transparent;
        background-color: transparent;
        background-image: none;
        width: auto;
    }
    .education-title > img {
        padding-top: 0;
        /* width: 110px; */
    }
    .card-title > a {
        cursor: pointer;
    }
    .button-vp > a {
        cursor: pointer;
    }
}