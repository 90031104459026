.carousel-title {
    font-size: 2.5rem;
    color: white;
}
.carousel-image {
    width: 100%;
}
.button-back,
.button-next {
    font-size: 1.4rem;
    color: white;

}
.carousel-item {
  position: relative;
}
.project-label {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  background-color: rgba(0,0,0,0.6);
}
.projects-description {
  max-width: 500px;
  font-size: 1.4rem;
  text-align: center;
  /* padding-left: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* background-color: rgba(34,40,49,1); */
}
.view-live {
  height: 40px;
  border: none;
  border-radius: 10px;
  outline: none;
  background: rgb(0,173,181);
  background: linear-gradient(180deg, rgba(0,173,181,1) 0%, rgba(0,124,124,1) 100%);
}
.view-live > a {
  cursor: none;
  text-decoration: none;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0 10px;
}

@media(min-width: 768px) {
  .carousel-title {
    font-size: 4.2rem;
  }
  .projects-description {
    font-size: 1.4rem;
  }
}
@media(min-width: 1024px) {
  .view-live > a {
    cursor: pointer;
  }
}