.contact-title {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(1,75,88);
    background: linear-gradient(180deg, rgba(0,105,108,1) 0%, rgba(34,40,49,1) 90%);
}
.contact-title > img {
    padding-top: 6rem;
    width: 150px;
}
.contact-description {
    width: 100vw;
    height: 100vh;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    background-color: #222831;
    background-image: linear-gradient(180deg, #222831 10%, #393e46 100%);
}
.contact-description > li {
    width: 90vw;
    max-width: 320px;
    display: flex;
    justify-content: left;
    align-items: center;
    column-gap: 5px;
    padding: 50px 0;
    padding-left: 30px;
    border-radius: 20px;
    background: rgb(0,173,181);
    background: linear-gradient(0deg, rgba(0,173,181,1) 0%, rgba(0,248,248,1) 100%);
}
.contact-description > .Phone > img,
.contact-description > .Email > img,
.contact-description > .Address > img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(13%) 
    saturate(4424%) hue-rotate(149deg) brightness(99%) contrast(99%);
}
.contact-description > .Phone > img,
.contact-description > .Address > img {
    width: 30px;
}
.contact-description > .Email > img {
    width: 40px;
}
.contact-description__item > p {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--green-dark);
}
.contact-description__item > a {
    cursor: none;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--green-dark);
}
@media(min-width: 1024px) {
    .Contact {
        width: 100vw;
        height: 100vh;
        display: flex; /* Cambiar a Flex */
        justify-content: center;
        align-items: center;
        column-gap: 5vw;
        background: rgb(56,61,69);
        background: linear-gradient(0deg, rgba(56,61,69,1) 0%, rgba(34,40,49,1) 0%, rgba(0,124,124,1) 100%);
    }
    .contact-title, .contact-description {
        background: transparent;
        background-color: transparent;
        background-image: none;
        width: auto;
    }
    .contact-title > img {
        padding-top: 0;
        /* width: 180px; */
    }
    .contact-description > .Phone > img,
    .contact-description > .Address > img {
        width: 35px;
    }
    .contact-description > .Email > img {
        width: 45px;
    }
    .contact-description__item > p {
        font-size: 2.2rem;
    }
    .contact-description__item > a {
        cursor: pointer;
        font-size: 1.8rem;
    }
}